
import {
    defineComponent, reactive, ref
} from 'vue';
import List from '@/components/common/list';
import { alarmRecordsApi, pmCommonApi } from '@/api';
import { community } from '@/data';
import moment from 'moment';
import ExportLogDialog from './components/alarming-alarm-export-dialog.vue';

const column = [
    {
        name: 'AlarmTime',
        label: WordList.CaptureListTanleCaptureTime,
        type: 'customize'
    }, {
        name: 'Department',
        label: WordList.ProperAllTextDepartment
    }, {
        name: 'Owner',
        label: WordList.TabelPersonUserInHtmlName
    }, {
        name: 'Location',
        label: WordList.DeviceLocation
    }, {
        name: 'Information',
        label: WordList.ProperAllTextInformation
    }];
export default defineComponent({
    components: {
        ExportLogDialog,
        List
    },
    setup() {
        const formData = reactive({
            BeginTime: '',
            EndTime: '',
            row: '10',
            page: '1'
        });
        const tableData = reactive({
            total: 0,
            row: [],
            detail: []
        });

        const isShowExport = ref(false);

        const updateDate = ref(0);

        const getRecordsApi = () => {
            alarmRecordsApi.getOfficeArmingList(formData, (res: {
                total: number;
                row: never[];
                detail: never[];
            }) => {
                tableData.total = res.total;
                tableData.row = res.row;
                tableData.detail = res.detail;
                updateDate.value += 1;
            });
        };

        getRecordsApi();

        return {
            column,
            formData,
            getRecordsApi,
            tableData,
            isShowExport,
            updateDate
        };
    }
});
